import React, { Fragment, useState } from 'react';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import TapTextCard from '@components/TapTextCard';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import { graphql, Link } from 'gatsby';
import SEO from '@components/SEO';
import Header from '@components/Header';
import Accordion from '@components/Accordion';
import ContactCTA from '@components/ContactCTA';

const Faq = ({
    data: {
        page: {
            frontmatter: { title, description, questions },
        },
    },
}) => {
    const [openItemIndex, setOpenItemIndex] = useState(null);

    return (
        <Layout>
            <SEO title={`FAQ`} />
            <div className={styles.faqWrapper}>
                <Header
                    title={title}
                    description={description}
                    graphicName="/images/our-values-upholding-your-rights.svg"
                />
                <div className={styles.faqContent}>
                    {questions?.map(({ title, description }, index) => (
                        <Fragment key={title}>
                            <Row center="xs">
                                <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                                    <div className={styles.faqListContainer}>
                                        <Accordion
                                            open={openItemIndex === index}
                                            onToggle={open => setOpenItemIndex(open ? index : null)}
                                            title={title}
                                            html={description}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Divider margin={4} />
                        </Fragment>
                    ))}
                </div>
            </div>

            <Divider />
            <ContactCTA />
        </Layout>
    );
};

export default Faq;

export const query = graphql`
    query faqPage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: faq_title
                description: faq_description
                questions: faq_questions {
                    title: faq_questions_title
                    description: faq_questions_description
                }
            }
        }
    }
`;
